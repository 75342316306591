import styled from "styled-components";

const Section = styled.section`
  display: flex;
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
  box-sizing: border-box;
`;

export default Section;
